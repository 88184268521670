.single-card {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.dashboard-cards a {
  border-radius: 5px;
  transition: 0.5s;
}
.dashboard-cards a:hover {
  box-shadow: 0px 0px 10px 3px #ababab;
}
.dashboard-cards a:nth-child(1) {
  background: #012fad;
}
.dashboard-cards a:nth-child(2) {
  background: #725cff;
}
.dashboard-cards a:nth-child(3) {
  background: linear-gradient(#01d293, #56c57a);
}
.dashboard-cards a:nth-child(4) {
  background: linear-gradient(#ef621c, #e1424e);
}
.card-content h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.card-content span {
  color: #fff;
  font-size: 32px;
}

.card-icon {
  font-size: 32px;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 0.845);
  border: 1px solid #fff;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  text-align: center;
  padding: 11px 0px;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  margin-bottom: 30px;
}

.statics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  margin-top: 2rem;
}

.stats {
  background: #181b3a;
  padding: 30px;
  border-radius: 5px;
  height: 320px;
  padding-bottom: 50px;
}

.tooltip-style {
  background: #0b0c28 !important;
  color: #fff;
}

.stats-title {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 20px;
}
