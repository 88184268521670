.rating i,
.ri-star-fill {
  color: #ebb450;
  font-size: 16px;
}
.review-item {
  background: #f0f4f7;
  border-radius: 5px;
  padding: 15px;
  font-size: 14px;
}
.review-item .name {
  font-size: 14px;
}
.review-item .date {
  margin: 10px 0;
}
.review-item .review-content {
  background: #cff4fc;
  padding: 10px;
}
.review-item p {
  margin-bottom: 0;
}
