.profile-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}
.profile-items img {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin-bottom: 20px;
}
.profile-items .left,
.profile-items .right {
  border: 1px solid #ced4da;
  text-align: center;
  padding: 30px 30px;
}
.profile-items .left ul {
  text-align: left;
}
.profile-items .left ul b {
  margin-right: 5px;
}
.profile-items .right a {
  display: inline-block;
  margin: 10px 0;
}
