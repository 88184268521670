.sidebar {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #181b3a;
  padding: 0px 25px;
}
.sidebar-top {
  width: 100%;
  height: 70px;
  line-height: 70px;
}
.sidebar-top .img {
  padding: 15px 0px;
}
.sidebar-top .img img {
  width: 120px;
  /* height: 45px; */
}
.sidebar-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 5%);
  margin-top: 30px;
}
.sidebar-menu {
  height: 100%;
  overflow: auto;
  padding-bottom: 80px;
}
.nav-link,
.sidebar-title span {
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  transition: 0.5s;
  font-size: 15px;
  justify-content: space-between;
}
.nav-list {
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  color: #808191;
  padding-left: 0;
}
.nav-link i,
.sidebar-title span > i {
  color: #012fad;
}

.sidebar-item.active {
  background: #b7ffe913;
  border-radius: 5px;
}
.sidebar-item.active:last-of-type {
  background: none;
  color: #b4b4b4;
}
.sidebar-item.active:last-of-type:hover {
  background: #b7ffe913;
}
.sidebar-item:hover,
.nav-active {
  /* background: #181b3a; */
  color: #fff !important;
  background: #b7ffe913;
}
.sidebar-item {
  padding: 8px 16px;
  display: block;
  border-radius: 5px;
  cursor: pointer;
  color: #b4b4b4;
}
.sidebar-title {
  display: flex;
  justify-content: space-between;
}
.sidebar-title span {
  cursor: pointer;
}
.sidebar-title span i {
  display: inline-block;
}
.sidebar-title .toggle-icon {
  cursor: pointer;
  transition: transform 0.5s;
}
.sidebar-item.active > .sidebar-title .toggle-icon {
  transform: rotate(180deg);
}
.sidebar-submenu {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.active > .sidebar-submenu {
  height: auto;
}
